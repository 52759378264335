import React from "react";

const GenerateTicket = ({ item, editTicket, viewTicket, deleteTicket }) => {
    const editHandler = (item) => {
        editTicket(item);
    };

    const viewHandler = (item) => {
        viewTicket(item);
    };

    const deleteHandler = (item) => {
        deleteTicket(item);
    };

    const { store, room, assistant, report } = item;
    return (
        <li className="cards__item">
            <div className="card">
                {deleteTicket && (
                    <div
                        onClick={() => deleteHandler(item)}
                        className="delete__button"
                    >
                        X
                    </div>
                )}
                <div className="card__content">
                    <div className="card__title"> Store: {store} </div>
                    <p className="card__text"> Room: {room} </p>
                    <p className="card__text"> Report: {report} </p>
                    <p className="card__text">
                        GAMEOVER Assistant: {assistant}
                    </p>
                    {editTicket ? (
                        <button
                            onClick={() => editHandler(item)}
                            className="btn btn--block card__btn"
                        >
                            EDIT
                        </button>
                    ) : (
                        <button
                            onClick={() => viewHandler(item)}
                            className="btn btn--block card__btn"
                        >
                            VIEW
                        </button>
                    )}
                </div>
            </div>
        </li>
    );
};

export default GenerateTicket;
