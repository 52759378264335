import React from "react";
import titleHelper from "./titleHelper";
const TextArea = ({ name, handleChange, isDisabled, content }) => (
    <>
        <div className="textColumn">
            {titleHelper({ name })}
            {name !== "email" ? (
                <>
                    <textarea
                        onChange={handleChange}
                        name={name}
                        id={name}
                        cols="10"
                        rows="5"
                        disabled={isDisabled ? true : false}
                        defaultValue={isDisabled ? content : content}
                    />
                </>
            ) : (
                <>
                    <input
                        type="email"
                        id="email"
                        name="email"
                        disabled={isDisabled ? true : false}
                        defaultValue={isDisabled ? content : content}
                        onChange={handleChange}
                    />
                </>
            )}
        </div>
    </>
);

export default TextArea;
