import React from "react";

const titleHelper = ({ name }) => {
    const titles = {
        report: "FAILURE REPORT",
        cause: "FAILURE CAUSE",
        assistant: "CUBE Name",
        client: "Client's Name",
        email: "Client's Email",
    };

    return (
        <label id={name} className="label">
            {titles[name]}
        </label>
    );
};

export default titleHelper;
