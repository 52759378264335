import React from "react";
import $ from "jquery";
import DropDown from "../TicketForm/Dropdown";
import TextArea from "../TicketForm/Textarea";
import GenerateCountryList from "../utils/generateCountryList";
import GenerateStoreList from "../utils/generateStoreList";
import GenerateRoomList from "../utils/generateRoomList";
import timeHelper from "../utils/timeHelper";
import CheckBoxes from "../Common/Checkboxes/checkBoxes";
import { updateTicket } from "../../services/api";
import "./style.css";

class TicketTemplate extends React.Component {
    constructor(props) {
        super(props);
        if (this.props.calledFromPending) {
            this.state = {
                ...props.data,
                status: "pending",
                isDisabled: true,
            };
        } else {
            this.state = {
                ...props.data,
                status: "completed",
                isDisabled: true,
                isSolved: true,
            };
        }

        this.handleUpdate = this.handleUpdate.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleCheckboxFilter = this.handleCheckboxFilter.bind(this);
    }

    handleChange(event) {
        const { name, value } = event.target;
        // console.log(name, value);

        if (name === "country") {
            this.setState(
                {
                    [name]: value,
                },
                () => {
                    let store = document.getElementsByName("store")[0].value;
                    this.setState(
                        {
                            store,
                        },
                        () => {
                            let room = document.getElementsByName("room")[0]
                                .value;
                            console.log(room);
                            this.setState({
                                room,
                            });
                        }
                    );
                }
            );
        } else if (name === "store") {
            this.setState(
                {
                    [name]: value,
                },
                () => {
                    let room = document.getElementsByName("room")[0].value;
                    this.setState({
                        room,
                    });
                }
            );
        }

        this.setState({
            [name]: value,
        });
    }

    handleCheckboxFilter(e) {
        const checkbox = e.target.name;
        const isChecked = e.target.checked;

        document.querySelectorAll("input[type=checkbox]").forEach((element) => {
            element.checked = false;
        });

        document.querySelectorAll("input[type=checkbox]").forEach((element) => {
            if (element.name === checkbox) {
                element.checked = true;
                this.setState({
                    department: checkbox,
                });
            }
        });
    }

    backButton = () => {
        this.props.resetshowTicketForm();
    };

    navigateToHome = () => {
        if (this.props.isAdmin && this.state.solution !== "") {
            this.props.updateTicketList(this.state);
            this.props.handleTicketCounterTemplate();
        } else {
            this.props.resetshowTicketForm();
            this.props.updateTicketList(this.state);
        }
    };

    handleCheckbox = (event) => {
        const { name, value } = event.target;

        let completedDate = timeHelper();

        if (name === "solution" && value.length > 0) {
            this.setState({
                isSolved: true,
                isDisabled: false,
                [name]: value,
                status: "completed",
                completed_date: completedDate,
            });
        } else {
            this.setState({
                isSolved: false,
                isDisabled: true,
                status: "pending",
                solution: "",
            });
        }
    };

    createCheckBoxes = (checkboxes) =>
        checkboxes.map((item, index) => <CheckBoxes key={index} name={item} />);

    handleTicketCounterTemplate = () => {
        let newTicketCounter = 0;
        let oldTicketCounter = this.props.pendingsStatus;

        newTicketCounter = oldTicketCounter - 1;
        this.props.handleTicketCounterPending(newTicketCounter);
    };

    handleUpdate = async (event) => {
        event.preventDefault();

        let data = {};

        if (this.props.isAdmin) {
            data = {
                ...this.state,
                admin: this.props.isAdmin,
            };
        } else {
            data = {
                ...this.state,
                admin: false,
            };
        }
        await updateTicket(data, (status) => {
            if(status === 'failed') {
                this.setState({
                    errorMessage: "A problem has occurred",
                });
                return 
            }
            if(status === 'solution') {
                return this.handleTicketCounterTemplate();
            }
            if(status === 'navigate') {
                return this.navigateToHome();
            }
            if(status === 'error') {
                return this.setState({
                    errorMessage: "A problem has occurred",
                })
            }
        })
    };

    render() {
        return (
            <div style={{ marginTop: "5vh" }}>
                <div className="backButton" onClick={this.backButton}>
                    &larr;
                </div>
                <form className="formContainer">
                    <div className="formItem">
                        {this.props.isAdmin ? (
                            <>
                                <DropDown
                                    name="country"
                                    handleChange={this.handleChange}
                                    value={this.state.country}
                                >
                                    <GenerateCountryList />
                                </DropDown>
                                <DropDown
                                    name="store"
                                    handleChange={this.handleChange}
                                    value={this.state.store}
                                >
                                    <GenerateStoreList
                                        selectedCountry={this.state.country}
                                    />
                                </DropDown>
                                <DropDown
                                    name="room"
                                    handleChange={this.handleChange}
                                    value={this.state.room}
                                >
                                    {GenerateRoomList(this.state.store)}
                                </DropDown>
                            </>
                        ) : (
                            <>
                                <DropDown name="country">
                                    <option value={this.state.country}>
                                        {this.state.country}
                                    </option>
                                </DropDown>
                                <DropDown name="store">
                                    <option value={this.state.store}>
                                        {this.state.store}
                                    </option>
                                </DropDown>
                                <DropDown name="rooms">
                                    <option value={this.state.room}>
                                        {this.state.room}
                                    </option>
                                </DropDown>
                            </>
                        )}
                        <div>
                            DATE:
                            <span> {this.state.created_date} </span>
                            <div id="ticketForm__checkbox">
                                <CheckBoxes
                                    name="electronic"
                                    handleCheckboxFilter={
                                        this.handleCheckboxFilter
                                    }
                                    isDisabled={
                                        this.props.isAdmin ? false : true
                                    }
                                    checked={this.state.department}
                                />
                                <CheckBoxes
                                    name="other"
                                    handleCheckboxFilter={
                                        this.handleCheckboxFilter
                                    }
                                    isDisabled={
                                        this.props.isAdmin ? false : true
                                    }
                                    checked={
                                        !this.props.isAdmin
                                            ? this.state.department
                                            : undefined
                                    }
                                />
                            </div>
                        </div>
                    </div>
                    <div className="formItem-2">
                        <div className="row">
                            <TextArea
                                name="report"
                                handleChange={this.handleChange}
                                isDisabled={this.props.isAdmin ? false : true}
                                content={this.state.report}
                            />
                            <TextArea
                                name="cause"
                                handleChange={this.handleChange}
                                isDisabled={this.props.isAdmin ? false : true}
                                content={this.state.cause}
                            />
                        </div>
                        <div className="row">
                            <TextArea
                                name="assistant"
                                handleChange={this.handleChange}
                                isDisabled={this.props.isAdmin ? false : true}
                                content={this.state.assistant}
                            />
                            <TextArea
                                name="client"
                                handleChange={this.handleChange}
                                isDisabled={this.props.isAdmin ? false : true}
                                content={this.state.client}
                            />
                        </div>
                        <TextArea
                            name="email"
                            handleChange={this.handleChange}
                            isDisabled={this.props.isAdmin ? false : true}
                            content={this.state.email}
                        />
                        <div className="solutionInput">
                            <label className="label"> SOLUTION </label>
                            <textarea
                                name="solution"
                                type="text"
                                onChange={this.handleCheckbox}
                                value={
                                    this.state.solution !== ""
                                        ? this.state.solution
                                        : ""
                                }
                                disabled={this.props.calledFromCompleted}
                            />
                            <p className="checkBoxStyle">
                                <input
                                    id="test1"
                                    name="isSolved"
                                    type="checkbox"
                                    readOnly
                                    checked={this.state.isSolved}
                                    onChange={this.handleCheckbox}
                                />
                                <label htmlFor="test1">Resolved</label>
                            </p>
                        </div>
                        {this.props.calledFromPending && (
                            <p
                                className="submitButton"
                                disabled={this.state.isDisabled}
                                onClick={this.handleUpdate}
                            >
                                UPDATE
                            </p>
                        )}
                    </div>
                </form>
            </div>
        );
    }
}
export default (TicketTemplate);
