import React from "react";

const DropDown = ({ name, handleChange, children, defaultValue }) => (
    <>
        <label className="label"> {name.toUpperCase()} </label>
        <div className="optionDropDown">
            <select
                name={name}
                value={defaultValue}
                onChange={(e) => handleChange(e)}
            >
                {children}
            </select>
        </div>
    </>
);

export default DropDown;
