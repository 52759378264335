import React, { createRef, useEffect } from "react";
import { Link } from "react-router-dom";
import Login from "../Login";
import Logo from "../../assets/logo.svg";
import HomeImg from "../../assets/addbutton.svg";
import PendingImg from "../../assets/pending.svg";
import CompletedImg from "../../assets/completed.svg";
import "./style.css";

class NavBar extends React.Component {
  handleLogin = (data) => {
    this.props.handleLogin(data);
  };

  handleLogout = () => {
    this.props.handleLogout();
  };

  componentDidMount() {}

  render() {
    const counterValues = [
      this.props.counter.completed.length,
      this.props.counter.pending.length,
    ];
    const counterRef = createRef();
    setInterval(() => {
      const temp = counterValues[0];
      counterValues[0] = counterValues[1];
      counterValues[1] = temp;
      if (counterRef.current) {
        counterRef.current.innerHTML = counterValues[0] || '0';
      }
    }, 5000);

    return (
      <nav className="navBar">
        <div>
          <Link to="/">
          <img className="imgFluid" src={Logo} alt="" />
          </Link>
        </div>

        <ul className="menuLinks">
          <li>
            <Link to="/form" replace>
              <img className="navBarIcons" src={HomeImg} alt="" />
            </Link>
          </li>
          <li>
            <Link to="/" replace>
              <img className="navBarIcons" src={PendingImg} alt="" />
              <div ref={counterRef} className="pendingsCounter"></div>
            </Link>
          </li>
          <li>
            <Link to="/completed" replace>
              <img className="navBarIcons" src={CompletedImg} alt="" />
            </Link>
          </li>
        </ul>

        <Login
          handleLogin={this.handleLogin}
          handleLogout={this.handleLogout}
          loginProfile={this.props.loginProfile}
        />
      </nav>
    );
  }
}

export default NavBar;
