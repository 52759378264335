import React, { Component } from "react";
import style from "./style.css";
class Login extends Component {
    constructor(props) {
        super(props);

        this.state = {
            username: "",
            password: "",
            loginErrors: "",
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event) {
        const { name, value } = event.target;
        this.setState({
            [name]: value,
        });
    }

    handleSubmit(event) {
        event.preventDefault();

        const { username, password } = this.state;

        let data = {
            username,
            password,
        };

        this.props.handleLogin(data);
    }

    render() {
        return (
            <>
                {this.props.loginProfile ? (
                    <div>
                        <h1>Hello {this.props.loginProfile}!</h1>
                        <button
                            className="logOutButton"
                            onClick={this.props.handleLogout}
                        >
                            Log out
                        </button>
                    </div>
                ) : (
                    <form onSubmit={this.handleSubmit}>
                        <input
                            type="text"
                            name="username"
                            placeholder="username"
                            value={this.state.username}
                            onChange={this.handleChange}
                            required
                        />

                        <input
                            type="password"
                            name="password"
                            placeholder="Password"
                            value={this.state.password}
                            onChange={this.handleChange}
                            required
                        />

                        <button type="submit">Login</button>
                    </form>
                )}
            </>
        );
    }
}

export default Login;
