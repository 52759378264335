import React from "react";
import "./searchBar.css";

const SearchBar = ({ searchInput, updateSearch }) => {
    return (
        <div id="searchBar" className="searchBar___container">
            <input
                id="search"
                name="search"
                type="text"
                placeholder="What're we looking for? "
                value={searchInput}
                onChange={(e) => updateSearch(e)}
            />
            <input
                id="search_submit"
                value="Rechercher"
                type="submit"
                disabled={true}
            />
        </div>
    );
};

export default SearchBar;
