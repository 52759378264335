import React from "react";
import ViewImg from "../../assets/eyeView.svg";

const GenerateTicketTable = ({ item, viewTicket }) => {
    const viewHandler = (item) => {
        viewTicket(item);
    };

    const { id, store, country, room, completed_date } = item;
    return (
        <tr>
            <td>{id}</td>
            <td>{store}</td>
            <td>{country}</td>
            <td>{room}</td>
            <td>{completed_date}</td>
            <td>
                <img
                    width="30"
                    height="30"
                    src={ViewImg}
                    alt="View Icon"
                    onClick={() => viewHandler(item)}
                />
            </td>
        </tr>
    );
};

export default GenerateTicketTable;
