import React from "react";
import CheckBoxes from "../Common/Checkboxes";
import "../Common/Checkboxes/checkBoxes.css";

const renderCheckBoxes = (
    checkboxes,
    handleCheckboxFilter,
    handleDepartmentType
) => {
    return checkboxes.map((item, index) => (
        <CheckBoxes
            key={index}
            name={item.name || item}
            isChecked={item.isChecked}
            handleCheckboxFilter={
                handleCheckboxFilter ? handleCheckboxFilter : undefined
            }
            handleDepartmentType={
                handleDepartmentType ? handleDepartmentType : undefined
            }
        />
    ))
};

export default renderCheckBoxes;
