import React from "react";
import $ from "jquery";
import "./style.css";
import GenerateTicket from "../utils/generateTicket";
import TicketTemplate from "../TicketTemplate/TicketTemplate";
import renderCheckBoxes from "../Helpers/renderCheckBoxes";

import filterAll from "../utils/filterAll";
import { filterBy, filterByDept } from "../utils/filterBy";
import SearchBar from "../Common/SearchBar";
import { deleteTicket, fetchPendingTickets } from ".././../services/api";
import { DEPARTMENT_CHECKBOXES, HOME_CHECKBOXES } from "../../constants";
// import Test from "../../test";

class Home extends React.Component {
  constructor() {
    super();

    this.state = {
      tickets: [],
      filteredTicket: [],
      groupByTicket: [],
      itemData: {},
      search: "",
      filterAll: [],
      filterBy: "",
      checkboxes: HOME_CHECKBOXES,
      department: DEPARTMENT_CHECKBOXES,
      showPendings: true,
      showTicketForm: false,
      hasSearched: false,
      deleteConfirmation: false,
    };

    this.handleCheckboxFilter = this.handleCheckboxFilter.bind(this);
    this.handleDepartmentType = this.handleDepartmentType.bind(this);
  }

  handleCheckboxFilter(event) {
    const {name} = event.target;
    const boxes = this.state.checkboxes
    boxes.forEach(box => box.isChecked = false);
    boxes.find(box => box.name === name).isChecked = true
    
    this.setState(prev => ({
      ...prev, 
      filterBy: name,
      checkboxes: boxes
    }));
  }

  handleDepartmentType = (event) => {
    const {name} = event.target;
    const { tickets } = this.state;
    const dept = this.state.department
    dept.forEach(box => box.isChecked = false);
    dept.find(box => box.name === name).isChecked = true
    let filteredTicket = tickets.filter(ticket => ticket.department === name);

    this.setState(prev => ({
      ...prev,
      department: dept,
      filteredTicket,
      showPendings: true,
      search: "",
      hasSearched: false,
    }))
  };

  editTicket = (itemData) => {
    this.setState(
      {
        itemData,
      },
      () => {
        this.setState({
          showTicketForm: true,
          showPendings: false,
          showTickets: false,
          hasSearched: false,
        });
      }
    );
  };

  deleteTicket = (itemData) => {
    console.log(itemData);
    this.setState(
      {
        itemData,
      },
      () => {
        this.setState({
          deleteConfirmation: true,
        });
      }
    );
  };

  removeTicketFromList = (id) => {
    console.log(id);
    let tempTicketList = this.state.tickets;

    let newTicketList = tempTicketList.filter((el) => el.id !== id);

    this.setState({
      tickets: newTicketList,
    });

    let newTicketCounter = 0;
    let oldTicketCounter = this.props.pendingsStatus;

    newTicketCounter = oldTicketCounter - 1;
    this.props.handleTicketCounter(newTicketCounter);
  };

  submitDelete = async (e) => {
    e.preventDefault();
    let itemData = this.state.itemData;
    return await deleteTicket().then(res => {
      if (res === 'success') {
        this.setState({
          deleteConfirmation: false,
        });
        this.removeTicketFromList(itemData.id);
        return
      }
    })
  };

  updateSearch(event) {
    let tempSearchTickets = [];

    if (event.target.value !== "") {
      this.setState({
        hasSearched: true,
        showTicketForm: false,
      });
    } else {
      this.setState({
        hasSearched: false,
        showTickets: true,
      });
    }
    this.setState(
      {
        search: event.target.value.substr(0, 20),
      },
      () => {
        if (this.state.filterBy === "") {
          tempSearchTickets = filterAll(
            this.state.filteredTicket,
            this.state.search
          );
        } else {
          let tempFilterBy = this.state.filterBy;

          tempSearchTickets = filterBy(
            this.state.filteredTicket,
            this.state.search,
            tempFilterBy
          );
        }

        this.setState({
          showPendings: true,
          groupByTicket: tempSearchTickets,
        });
      }
    );
  }

  showPendingTickets = (ticketsToMap) =>
    ticketsToMap.map((item, index) => (
      <GenerateTicket
        key={index}
        item={item}
        editTicket={item.solution === "" ? this.editTicket : undefined}
        deleteTicket={
          this.props.profile === "admin" ? this.deleteTicket : undefined
        }
      />
    ));

  handleTicketCounterPending = (newTicketCounter) => {
    this.props.handleTicketCounter(newTicketCounter);
  };

  resetshowTicketForm = () => {
    this.setState({
      showTicketForm: false,
      showPendings: true,
      deleteConfirmation: false,
    });
  };

  updateTicketList = (itemData) => {
    let ticketList = this.state.tickets;

    ticketList.map((el) => {
      if (el.id === itemData.id) {
        Object.assign(el, itemData);
      }
    });

    this.setState({
      showTicketForm: false,
      showPendings: true,
    });
  };

  async componentDidMount() {
    if(this.props.isLoggedIn) {
      const res = await fetchPendingTickets('pending')
      this.setState((prev) => ({ ...prev, ...res }));
    }
  }

  render() {
    // console.log(this.state)
    return (
      <>
        {!this.props.isLoggedIn ? (
          <></>
        ) : (
          <>
            {this.state.showPendings && (
              <SearchBar
                searchInput={this.state.search}
                updateSearch={this.updateSearch.bind(this)}
              />
            )}
            {this.state.showPendings && (
              <div className="filterSearchContainer">
                <div className="filterCheckBoxes">
                  <h2>Department</h2>
                  <div id="department">
                    {renderCheckBoxes(
                      this.state.department,
                      this.handleDepartmentType.bind(this)
                    )}
                  </div>
                </div>
                <ul className="cards">
                  {
                    this.showPendingTickets(this.state.filteredTicket)
                  }
                </ul>
              </div>
            )}
            {this.state.showTicketForm && (
              <TicketTemplate
                data={this.state.itemData}
                calledFromPending={true}
                resetshowTicketForm={this.resetshowTicketForm}
                handleTicketCounterPending={this.handleTicketCounterPending}
                pendingsStatus={this.props.pendingsStatus}
                isAdmin={this.props.profile === "admin" ? true : false}
                updateTicketList={this.updateTicketList}
              />
            )}
            {this.state.deleteConfirmation && (
              <>
                <div className="deletePopupContainer">
                  Delete this ticket ?
                  <div onClick={this.submitDelete}> yes </div>
                  <div onClick={this.resetshowTicketForm}> no </div>
                </div>
                <div className="popupOverlay"> </div>
              </>
            )}
          </>
        )}
      </>
    );
  }
}

export default Home;
