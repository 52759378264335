const timeHelper = () => {
    let tempDate = new Date();

    let date =
        tempDate.getFullYear() +
        "-" +
        (tempDate.getMonth() + 1) +
        "-" +
        tempDate.getDate();

    return date;
};

export default timeHelper;
